module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tim Ohrel | Senior Product Designer","short_name":"timohrel.com","description":"I'm a Senior Product Designer from France. I specialize in working on SaaS products, building and managing Design Systems, and collaborating with development teams.","lang":"en","start_url":"/en","background_color":"#FFF199","theme_color":"#FFF199","display":"standalone","icon":"src/images/favicons/favicon.png","localize":[{"start_url":"/fr","lang":"fr","name":"Tim Ohrel | Senior Product Designer","short_name":"timohrel.com","description":"I'm a Senior Product Designer from France. I specialize in working on SaaS products, building and managing Design Systems, and collaborating with development teams."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92174c1f419909e6e8209583900f7fda"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-32923910-8","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://timohrel.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
